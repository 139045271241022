import React from 'react'

import {
  InlineList,
  InlineListItem,
  Paragraph,
  Text
} from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="InlineList"
    components={[{ component: InlineList }, { component: InlineListItem }]}
    status={[{ type: 'accessible', version: '15.1.0' }]}
  >
    <Section>
      <Playground>
        <InlineList>
          <InlineListItem>Palvelu</InlineListItem>
          <InlineListItem>ELY-keskus</InlineListItem>
        </InlineList>
      </Playground>
      <Playground>
        <Text
          as="div"
          color="dark"
          size="sm"
        >
          <InlineList>
            <InlineListItem>Palvelu</InlineListItem>
            <InlineListItem>ELY-keskus</InlineListItem>
          </InlineList>
        </Text>
      </Playground>
      <Playground format="html">
        <ul className="inline-list">
          <li className="inline-list-item">Palvelu</li>
          <li className="inline-list-item">ELY-keskus</li>
        </ul>
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>Tuottaa semanttisesti listan.</Paragraph>
    </Section>
  </Content>
)

export default Page
